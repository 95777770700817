.App {
  text-align: center;
}

.App-logo {
  height: 38vmin;
  pointer-events: none;
  fill: #737e9e; 
  cursor: pointer;
}



.App-header {
  background-color: #13141a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: #737e9e;
}

.App-link {
  color: #161b2c;
}
.App-tagline {
color: rgb(2, 5, 43);
font-size: calc(2px + 2vmin);
}

.App-logo-large {
  height: 12rem; /* Adjust size as needed */
}

.App-logo-small {
  height: 3rem; /* Adjust size as needed */
}

.App-nav {
  /* Styles for your navigation bar */
}


/* Example CSS */
.user-session-container {
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: #0d0d0d;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-session-container h2 {
    color: #333;
    text-align: center;
}

.user-session-container p {
    color: #555;
    font-size: 1rem;
}




@media (prefers-reduced-motion: no-preference) {
 
}

.kb-rotation-eases-slow {
  animation: kb-rotation 72s ease-in-out infinite  both;
}

.kb-pulse-long-small {
  animation: kb-pulse-small 5s ease-in-out infinite;
} 

.kb-flicker-fast {
  animation: kb-flicker1 2s linear both;
}
/* Rest of your keyframes and CSS */




@keyframes kb-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes kb-pulse-small {
  0%, 100% { transform: scale(.97); }
  50% { transform: scale(1.03); } 
   
}

@keyframes kb-flicker1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  45% {
    opacity: 0;
  }
  45.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  55.1% {
    opacity: 0;
  }
  57% {
    opacity: 0;
  }
  57.1% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  60.1% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  65.1% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  75.1% {
    opacity: 0;
  }
  77% {
    opacity: 0;
  }
  77.1% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  85.1% {
    opacity: 0;
  }
  86% {
    opacity: 0;
  }
  86.1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes kb-flicker-prime-curve {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  10.1% {
    opacity: 1;
  }
  10.2% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  20.1% {
    opacity: 1;
  }
  20.6% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  30.1% {
    opacity: 1;
  }
  30.5% {
    opacity: 1;
  }
  30.6% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  50.1% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  80.1% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
}



