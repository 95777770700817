
/* FileUpload.css */
.file-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: auto;
}

.file-upload-header {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.file-upload-input {
  margin-bottom: 20px;
}

.upload-button,
.control-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 4px;
}

.upload-button:hover,
.control-button:hover {
  background-color: #45a049;
}

.progress-bar-wrapper {
    width: 100%;
    height: 12px; /* Same as progress bar */
    margin-bottom: 20px;
}

.progress-bar-container {
    width: 100%;
    background-color: #ddd;
    border-radius: 4px;
    height: 100%; /* Fill the wrapper */
    overflow: hidden;
}


.progress-bar-fill {
  background-color: #4CAF50;
  height: 100%; /* Fill the container */
  width: 0%; /* Initial width */
  text-align: right;
  line-height: 6px;
  color: white;
  transition: width 0.5s ease; /* Smooth transition for width */
}

