.cif {
    display: grid;
    grid-template-areas: "header header" "nav content";
    grid-template-columns: 222px 1fr;
    grid-template-rows: 50px 1fr;
    height: 100vh;
    background-color: #13141a;
    padding:48px;
}

.cif-header {
    grid-area: header;
    
}

.cif-nav {
    grid-area: nav;
    /* Add your styles here */
}

.cif-content {
    grid-area: content;
    /* Add your styles here */
}

.cif-container {
    width: 100%;
    padding: 16px;
    border-radius: 3.2px;
    background-color: #12111b;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

