.kb-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.kb-form input {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #737e9e;
    border-radius: 2.7px;
}

.kb-form button {
    width: 80%;
    padding: 10px;
    margin-top: 10px;
    background-color: #737e9e;
    color: white;
    border: 0.5px solid #737e9e;
    border-radius: 2.7px;
    cursor: pointer;
}

.kb-form button:hover {
    background-color: #333847;
    
}
